import { Box, HStack, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { KYDTicketType } from "@common/interfaces/KYDTicket";

import ticketIcon from "../assets/ticket.png";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useState } from "react";

export interface KYDTicketTypeSummary {
  id: string;
  name: string;
  description?: string;
  display_price?: string;
}

const TicketTypeWidget = ({
  ticketType,
  onChangeCount,
  disabled = false,
  maximum = 10,
  hidePrice = false,
  isListed = false,
}: {
  ticketType: KYDTicketTypeSummary | null;
  onChangeCount: (value: number) => void;
  disabled?: boolean;
  maximum?: number;
  hidePrice?: boolean;
  isListed?: boolean;
}) => {
  const [count, _setCount] = useState(0);

  const setCount = (value: number) => {
    _setCount(value);

    onChangeCount(value);
  };

  if (!ticketType) return null;

  return (
    <Box sx={{ width: "full", borderRadius: "3xl", backgroundColor: "black" }}>
      <VStack w="full">
        <HStack w="full" justifyContent={"space-between"} color="white" p={4}>
          <VStack w="full" alignItems={"flex-start"} spacing={0}>
            <HStack>
              <Image src={ticketIcon} width={"18px"} />
              <Text fontWeight={"bold"}>{ticketType.name?.toUpperCase()}</Text>
              {/*<Text minWidth={20}> (max: {maximum})</Text>*/}
            </HStack>
          </VStack>
          {!hidePrice ? (
            <Text
              fontSize={24}
              color="lightGreen"
              fontFamily={"work sans"}
              fontWeight={"bold"}
            >
              {ticketType.display_price}
            </Text>
          ) : null}
        </HStack>
        {isListed ? (
          <HStack
            color="white"
            borderTopWidth={1}
            borderColor="#494949"
            borderRadius="3xl"
            borderTopRightRadius={0}
            borderTopLeftRadius={0}
            w="full"
            justifyContent={"space-around"}
            alignItems={"center"}
            py={4}
            px={8}
            background="linear-gradient(to right, #2C2C2C 0%, black 50%, #2C2C2C 100%)"
          >
            <Text>Currently Listed</Text>
          </HStack>
        ) : (
          <HStack
            color="white"
            borderTopWidth={1}
            borderColor="#494949"
            borderRadius="3xl"
            borderTopRightRadius={0}
            borderTopLeftRadius={0}
            w="full"
            justifyContent={"space-around"}
            alignItems={"center"}
            py={4}
            px={8}
            background="linear-gradient(to right, #2C2C2C 0%, black 50%, #2C2C2C 100%)"
          >
            <IconButton
              backgroundColor="transparent"
              width={"56px"}
              height={"56px"}
              aria-label="Add icon"
              disabled={count === 0 || disabled}
              isDisabled={count === 0 || disabled}
              icon={<FaMinus color="white" />}
              onClick={() => setCount(count - 1)}
              _hover={{
                backgroundColor: "#494949",
              }}
              borderRadius={"full"}
            />
            <Box
              backgroundColor="black"
              borderRadius="full"
              width={"56px"}
              height={"56px"}
              background="#494949"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                textAlign={"center"}
                fontSize={28}
                fontWeight={"bold"}
                height={"56px"}
                lineHeight={"56px"}
              >
                {count}
              </Text>
            </Box>
            <IconButton
              backgroundColor="transparent"
              _hover={{
                backgroundColor: "#494949",
              }}
              disabled={disabled || count === maximum}
              isDisabled={disabled || count === maximum}
              borderRadius={"full"}
              icon={<FaPlus color="white" />}
              width={"56px"}
              height={"56px"}
              aria-label="Add icon"
              onClick={() => setCount(count + 1)}
            />
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default TicketTypeWidget;
