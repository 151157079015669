import { API } from "aws-amplify";
import {
  CreateAffiliatesRequest,
  CreatePromoCodeRequest,
  KYDAdminEvent,
  KYDEvent,
  KYDEventBundle,
  KYDEventMetrics,
  UpdateAffiliateRequest,
  UpdatePromoCodeRequest,
} from "@common/interfaces/KYDEvent";
import { KYDCart, KYDCartV2 } from "@common/interfaces/KYDCart";
import { IKYDUser, Wallet } from "@common/interfaces/KYDUser";
import {
  CreateTicketTypeRequest,
  GuestlistUserStatus,
  KYDTicket,
  KYDTicketType,
  ScanResponse,
} from "@common/interfaces/KYDTicket";
import { API_NAMES, getCurrentParamsUrl } from "../utils";
import { S3Response } from "react-s3-uploader";
import {
  KYDAdminFeed,
  KYDExternalLinkMetadata,
  KYDFeed,
  KYDFeedItem,
  KYDOrganizationFull,
} from "@common/interfaces/KYDOrganization";
import Stripe from "@stripe/stripe-js";
import { CheckoutParams } from "src/KYDEvent";

const fetchQueryParams = () => {
  if (window && window.location && window.location.search) {
    const params = new URLSearchParams(window.location.search);
    params.delete("update_id");
    const obj = Object.fromEntries(params);

    return obj;
  }
};

const _fetchAttributionData = () => {
  const obj: any = {};

  const _fbp = getCookie("_fbp");
  const _fbq = getCookie("_fbq");
  if (_fbp && _fbp.length > 0) {
    obj.fbp = _fbp;
  }

  if (_fbq && _fbq.length > 0) {
    obj.fbq = _fbq;
  }

  return obj;
};

const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const formatError = (err: any = {}) => {
  console.log(err.response);
  const { response = {} } = err;
  const { data = {}, status } = response;
  const { response: serverResponse = {} } = data;

  return {
    statusCode: status || 500,
    message: serverResponse.message || "An unknown error occured",
  };
};

const user_fetchEvent = async (event_id: string): Promise<KYDEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(API_NAMES.EVENT, `/events/${event_id}`, {
      queryStringParameters,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const user_fetchEventBundle = async (
  bundle_id: string
): Promise<KYDEventBundle> => {
  console.log("Fetch Bundle: ", bundle_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.EVENT,
      `/bundles/${bundle_id}`,
      {
        queryStringParameters,
      }
    );
    console.log(response);

    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const user_fetchBio = async (): Promise<KYDFeed> => {
  console.log("Fetch Bio");
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(API_NAMES.EVENT, `/bio`, {
      queryStringParameters,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const user_validatePaymentSession = async (url: string): Promise<KYDEvent> => {
  console.log("Validate Session: ", url);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(API_NAMES.EVENT, `/payments`, {
      queryStringParameters,
      body: {
        type: "applypay",
        request: { url },
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const user_applyPromoCode = async (event_id: string, promo_code: string) => {
  console.log("Apply Promo Code: ", promo_code);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/codes`,
      {
        queryStringParameters,
        body: { promo_code },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const user_removePromoCode = async (event_id: string, promo_code: string) => {
  console.log("Remove Promo Code: ", promo_code);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.del(
      API_NAMES.EVENT,
      `/events/${event_id}/codes`,
      {
        queryStringParameters,
        body: { promo_code },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchEvent = async (event_id: string): Promise<KYDAdminEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(API_NAMES.ADMIN, `/events/${event_id}`, {
      queryStringParameters,
    });
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createPromoCode = async (
  event_id: string,
  request: CreatePromoCodeRequest
): Promise<KYDAdminEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/events/${event_id}/codes`,
      {
        body: request,
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createTicketType = async (
  event_id: string,
  request: CreateTicketTypeRequest
) => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/events/${event_id}/ticket_types`,
      {
        body: request,
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createAffiliates = async (
  event_id: string,
  request: CreateAffiliatesRequest
): Promise<KYDAdminEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/events/${event_id}/affiliates`,
      {
        body: request,
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updatePromoCode = async (
  event_id: string,
  promo_code_id: string,
  request: UpdatePromoCodeRequest
): Promise<KYDAdminEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(
      API_NAMES.ADMIN,
      `/events/${event_id}/codes/${promo_code_id}`,
      {
        body: request,
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateAffiliate = async (
  event_id: string,
  affiliate_id: string,
  request: UpdateAffiliateRequest
): Promise<KYDAdminEvent> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(
      API_NAMES.ADMIN,
      `/events/${event_id}/affiliates/${affiliate_id}`,
      {
        body: request,
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateQRCode = async (
  qr_code_id: string,
  event_id: string
): Promise<KYDOrganizationFull> => {
  console.log("Update QR Code: ", qr_code_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(
      API_NAMES.ADMIN,
      `/links/${qr_code_id}`,
      {
        body: {
          event_id,
        },
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createUploadUrl = async (
  file: File,
  callback: (params: S3Response) => void | Promise<void | string>
) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(API_NAMES.ADMIN, `/upload`, {
      queryStringParameters,
      body: {
        filename: file.name,
      },
    });
    console.log("EV: ", response);
    return callback(response);
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchEventUpdate = async (
  event_id: string,
  update_id: string
): Promise<any> => {
  try {
    const { response } = await API.get(API_NAMES.ADMIN, `/events/${event_id}`, {
      queryStringParameters: {
        update_id,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchOrganization = async (): Promise<KYDOrganizationFull> => {
  console.log("Fetch Organization");
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(API_NAMES.ADMIN, `/organizations/me`, {
      queryStringParameters,
    });
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchBio = async (): Promise<KYDAdminFeed> => {
  console.log("Fetch Organization");
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.ADMIN,
      `/organizations/me/bio`,
      {
        queryStringParameters,
      }
    );
    console.log("EV: ", response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const fetchCart = async (event_id: string): Promise<KYDCartV2> => {
  console.log("Fetch Event: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.EVENT,
      `/events/${event_id}/cart`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const fetchEventWallet = async (event_id: string): Promise<Wallet> => {
  console.log("Fetch Event Wallet: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.EVENT,
      `/events/${event_id}/wallet`,
      { queryStringParameters }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const mockWaitlistCart = async (
  event_id: string,
  ticket_type: { id: string; quantity: number }
) => {
  console.log("fetchMockWaitlistCart:", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/cart/mock`,
      {
        body: { ticket_type_requests: [ticket_type] },
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const updateCart = async (
  event_id: string,
  cart: any,
  update_token?: string,
  access_code?: string
): Promise<any> => {
  console.log("Update Cart: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(
      API_NAMES.EVENT,
      `/events/${event_id}/cart`,
      {
        body: {
          cart: {
            ...cart,
            update_token,
          },
          access_code,
        },
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const clearCart = async (event_id: string, expiration_token: string) => {
  console.log("Update Cart: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/cart/clear`,
      {
        body: { expiration_token },
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const confirmTransfer = async (
  event_id: string,
  phone_number: string,
  ticket_ids: string[],
  first_name: string,
  last_name: string
) => {
  console.log("Send Transfer Request: ", ticket_ids);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/transfer`,
      {
        body: {
          phone_number: `+${phone_number}`,
          action: "transfer",
          ticket_ids,
          first_name,
          last_name,
        },
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const validatePhoneForTransfer = async (
  event_id: string,
  phone_number: string
) => {
  console.log("Validate Transfer: ", event_id);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/transfer`,
      {
        body: { phone_number: `+${phone_number}`, action: "validate" },
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const updateProfile = async (params: any) => {
  console.log("Update Profile: ", params);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(API_NAMES.EVENT, `/profile`, {
      body: { profile: params },
      queryStringParameters,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const createEventReminder = async (event_id: string) => {
  //console.log("Update Profile: ", params);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/reminders`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const createOnSaleReminder = async (event_id: string) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/reminders`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

type CheckoutResponse = {
  checkout_session_id: string;
  payment_intent: Stripe.PaymentIntent;
};
const createCheckout = async (
  event_id: string,
  expected_total: number,
  card_holder: any,
  type: CheckoutParams["type"],
  payment_method_id?: string,
  radar_session?: any
): Promise<CheckoutResponse> => {
  console.log("Create Checkout: ", event_id);
  try {
    if (!event_id) {
      throw { message: "No event id provided" };
    }
    const queryStringParameters = fetchQueryParams();

    const myInit = {
      body: {
        payment_method_id,
        expected_total,
        card_holder,
        type,
        radar_session,
      },
      queryStringParameters,
    };
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/checkoutv2`,
      myInit
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const cancelWaitlistOrder = async ({
  waitlist_id,
  event_id,
}: {
  event_id: string;
  waitlist_id: string;
}) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/waitlists/${waitlist_id}/cancel`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const createBuyOrder = async ({
  expected_total,
  quantity,
  payment_method_id,
  ticket_type_id,
  event_id,
  card_holder,
}: {
  event_id: string;
  expected_total: number;
  card_holder: any;
  type: CheckoutParams["type"];
  quantity: number;
  payment_method_id?: string;
  radar_session?: any;
  ticket_type_id: KYDTicketType["id"];
}): Promise<{
  id?: string;
  setup_intent?: {
    next_action?: {
      redirect_to_url: {
        return_url: string;
        url: string;
      };
    };
  };
}> => {
  const action = "BUY";

  const body = {
    ticket_type_id,
    expected_total,
    quantity,
    payment_method_id,
    action,
    profile: card_holder,
  };

  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/waitlists`,
      {
        body,
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const createSellOrder = async ({
  ticket_ids,
  event_id,
  ticket_type_id,
}: {
  event_id: string;
  ticket_ids: KYDTicket["id"][];
  ticket_type_id: KYDTicketType["id"];
}) => {
  const action = "SELL";
  const body = {
    ticket_type_id,
    action,
    ticket_ids,
  };

  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.EVENT,
      `/events/${event_id}/waitlists`,
      {
        body,
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const validateWalletToken = async (
  event_id: string,
  access_token: string,
  token: string,
  type?: string
): Promise<ScanResponse> => {
  console.log("Validate Token: ", token);
  try {
    if (!token) {
      throw { message: "No wallet token provided" };
    }

    const queryStringParameters = fetchQueryParams();
    const myInit = {
      body: {
        token,
        access_token,
        type,
      },
      queryStringParameters,
    };
    const { response } = await API.post(
      API_NAMES.SCANNER,
      `/events/${event_id}/validate`,
      myInit
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const validateAccessCode = async (
  event_id: string,
  code: string
): Promise<any> => {
  console.log("Validate Code: ", code);
  try {
    if (!code) {
      throw { message: "No code provided" };
    }

    const queryStringParameters = fetchQueryParams();
    const myInit = {
      body: {
        code,
      },
      queryStringParameters,
    };
    const { response } = await API.post(
      API_NAMES.SCANNER,
      `/events/${event_id}/accesscode`,
      myInit
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const createCheckIn = async (
  eventId: string,
  access_token: string,
  checkInToken: string
): Promise<ScanResponse> => {
  console.log("Create Check In: ", checkInToken);
  try {
    if (!checkInToken) {
      throw { message: "No check in token provided" };
    }

    const queryStringParameters = fetchQueryParams();
    const myInit = {
      body: {
        check_in_token: checkInToken,
        access_token,
      },
      queryStringParameters,
    };
    const { response } = await API.post(
      API_NAMES.SCANNER,
      `/events/${eventId}/checkin`,
      myInit
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchEvents = async (): Promise<KYDEvent[]> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(API_NAMES.ADMIN, `/events`, {
      queryStringParameters,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchEventMetrics = async (
  event_id: string,
  request?: { start?: string; end?: string }
): Promise<KYDEventMetrics> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.ADMIN,
      `/events/${event_id}/metrics`,
      {
        queryStringParameters: {
          ...queryStringParameters,
          ...(request || {}),
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateEvent = async (eventId: string, key: string, value: any) => {
  console.log(`Update Event: ${key}, ${value}`);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(API_NAMES.ADMIN, `/events/${eventId}`, {
      queryStringParameters,
      body: {
        request: {
          key,
          value,
        },
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateLink = async (linkId: string, request: any) => {
  console.log(`Update Link: ${linkId}, ${JSON.stringify(request)}`);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(API_NAMES.ADMIN, `/organizations/me`, {
      queryStringParameters,
      body: {
        feed_item_id: linkId,
        ...request,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createLink = async (link: string, type: KYDFeedItem["type"]) => {
  console.log(`Create Link: ${link}, ${type}`);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/organizations/me/feed`,
      {
        queryStringParameters,
        body: {
          link,
          type,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_launchEvent = async (eventId: string) => {
  console.log(`Launch Event: ${eventId}`);
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(API_NAMES.ADMIN, `/events/${eventId}`, {
      queryStringParameters,
      body: {
        request: {
          type: "launch",
        },
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateTicketType = async (
  eventId: string,
  ticketTypeId: string,
  request: any
) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(
      API_NAMES.ADMIN,
      `/events/${eventId}/ticket_types/${ticketTypeId}`,
      {
        queryStringParameters,
        body: {
          request,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_deleteTicketType = async (
  eventId: string,
  ticketTypeId: string
) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.del(
      API_NAMES.ADMIN,
      `/events/${eventId}/ticket_types/${ticketTypeId}`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_updateOrganization = async (action: string, request: any) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.put(API_NAMES.ADMIN, `/organizations/me`, {
      queryStringParameters,
      body: {
        action,
        request,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createEvent = async (request: any) => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(API_NAMES.ADMIN, `/events`, {
      queryStringParameters,
      body: {
        request,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const analytics_trackEvents = async (payload: any) => {
  try {
    const { event_id } = getCurrentParamsUrl();
    const params = fetchQueryParams();
    const res = await API.post(API_NAMES.ANALYTICS, `/`, {
      queryStringParameters: {
        ...params,
        event_id,
      },
      body: payload,
    });
    return res;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_festGuestlistUser = async ({ event_id, user_id }): Promise<any> => {
  try {
    const queryStringParameters = fetchQueryParams();
    console.log({ event_id, user_id });
    const { response } = await API.get(
      API_NAMES.ADMIN,
      `/events/${event_id}/guestlist/${user_id}`,
      {
        queryStringParameters,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_createGuestlistUser = async ({
  event_id,
  phone_number,
  ticket_type_id,
  guestlist_name,
  quantity,
}): Promise<{
  user_id: string;
  user_name: string;
  phone_number: string;
  quantity: number;
  did_reserve: boolean;
  statusCode?: number;
  message?: string;
  validation_result?: {
    is_valid?: boolean;
    message?: string;
  };
}> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/events/${event_id}/guestlist/assign`,
      {
        queryStringParameters,
        body: {
          phone_number,
          ticket_type_id,
          guestlist_name,
          quantity: parseInt(quantity),
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_deleteGuestlistUser = async ({
  event_id,
  phone_number,
  ticket_type_id,
}: {
  event_id: string;
  phone_number: string;
  ticket_type_id: string;
}): Promise<any> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.del(
      API_NAMES.ADMIN,
      `/events/${event_id}/guestlist`,
      {
        queryStringParameters,
        body: {
          phone_number,
          ticket_type_id,
        },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

export interface KYDUserWithGuestlist extends IKYDUser {
  guestlist: {
    assigned: number;
    ticket_type_id: string;
    status: GuestlistUserStatus;
  };
}

const admin_updateGuestlistAllocation = async ({
  event_id,
  quantity,
  ticket_type_id,
}: {
  event_id: string;
  quantity: number;
  ticket_type_id: string;
}): Promise<any> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.post(
      API_NAMES.ADMIN,
      `/events/${event_id}/guestlist`,
      {
        queryStringParameters,
        body: { quantity, ticket_type_id },
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchGuestlist = async (
  event_id: string
): Promise<{
  users: KYDUserWithGuestlist[];
  allocation: {
    remaining: number;
    allocation: number;
    assigned: number;
    ticket_type_id: string;
  };
}> => {
  try {
    const queryStringParameters = fetchQueryParams();
    const { response } = await API.get(
      API_NAMES.ADMIN,
      `/events/${event_id}/guestlist`,
      {
        queryStringParameters,
      }
    );
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const admin_fetchLinkMetadata = async (
  link: string
): Promise<{
  og: KYDExternalLinkMetadata;
  metadata: KYDExternalLinkMetadata;
}> => {
  try {
    const { response } = await API.post(API_NAMES.ADMIN, `/bio/validate`, {
      body: {
        link,
      },
    });
    console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    throw formatError(err);
  }
};

const USER = {
  createCheckout,
  fetchEvent: user_fetchEvent,
  fetchCart,
  fetchEventWallet,
  updateCart,
  updateProfile,
  clearCart,
  validatePhoneForTransfer,
  confirmTransfer,
  applyPromoCode: user_applyPromoCode,
  removePromoCode: user_removePromoCode,
  validatePaymentSession: user_validatePaymentSession,
  fetchBio: user_fetchBio,
  createEventReminder,
  createOnSaleReminder,
  mockWaitlistCart,
  createBuyOrder,
  createSellOrder,
  cancelWaitlistOrder,
  fetchEventBundle: user_fetchEventBundle,
};

const ADMIN = {
  fetchEvents: admin_fetchEvents,
  fetchEvent: admin_fetchEvent,
  fetchEventUpdate: admin_fetchEventUpdate,
  updateEvent: admin_updateEvent,
  updateTicketType: admin_updateTicketType,
  fetchOrganization: admin_fetchOrganization,
  updateOrganization: admin_updateOrganization,
  createUploadUrl: admin_createUploadUrl,
  createPromoCode: admin_createPromoCode,
  updatePromoCode: admin_updatePromoCode,
  updateQRCode: admin_updateQRCode,
  createAffiliates: admin_createAffiliates,
  updateAffiliate: admin_updateAffiliate,
  createTicketType: admin_createTicketType,
  deleteTicketType: admin_deleteTicketType,
  launchEvent: admin_launchEvent,
  fetchBio: admin_fetchBio,
  updateLink: admin_updateLink,
  createLink: admin_createLink,
  fetchEventMetrics: admin_fetchEventMetrics,
  createEvent: admin_createEvent,
  fetchLinkMetadata: admin_fetchLinkMetadata,
  fetchGuestlist: admin_fetchGuestlist,
  updateGuestlistAllocation: admin_updateGuestlistAllocation,
  deleteGuestlistUser: admin_deleteGuestlistUser,
  createGuestlistUser: admin_createGuestlistUser,
  fetchGuestlistUser: admin_festGuestlistUser,
};

const ANALYTICS = {
  trackEvents: analytics_trackEvents,
};

const SCANNER = {
  createCheckIn,
  validateAccessCode,
  validateWalletToken,
};

const KYD_APIS = {
  ADMIN,
  SCANNER,
  USER,
  ANALYTICS,
};

export default KYD_APIS;
