import {
  Badge,
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import {
  KYDTicketType,
  KYDWaitlistBuyOrder,
  KYDWaitlistOrder,
  KYDWaitlistSellOrder,
} from "@common/interfaces/KYDTicket";
import { Wallet } from "@common/interfaces/KYDUser";
import WaitlistTopCard, { InlineText } from "./WaitlistTopCard";
import dayjs from "dayjs";
import { formatDate } from "../../utils";
import { useDialogWithOptions } from "../../Common/Dialog";

const getBadgeColor = (waitlistOrder: KYDWaitlistBuyOrder | null) => {
  if (!waitlistOrder) return;

  const colorMap: Record<KYDWaitlistBuyOrder["status"], string> = {
    OPEN: "yellow",
    CANCELED: "yellow",
    FILLED: "lightGreen",
    PENDING_PAYMENT_METHOD: "yellow",
    PROCESSING: "yellow",
  };

  return colorMap[waitlistOrder.status];
};

const WaitlistTicketTypes = ({
  wallet,
  ticketTypes,
  timezone,
  onLeaveWaitlist,
  loading,
  type,
}: {
  wallet?: Wallet | null;
  ticketTypes?: KYDTicketType[];
  timezone?: string | null;
  onLeaveWaitlist: (
    tt: KYDTicketType,
    wo: KYDWaitlistBuyOrder | KYDWaitlistSellOrder
  ) => void;
  loading: Record<string, boolean>;
  type: "buy" | "sell";
}) => {
  const dialog = useDialogWithOptions();
  const waitlistTicketTypes =
    type === "buy"
      ? wallet?.buy_waitlist_orders.map((wo) => {
          return {
            ...ticketTypes.find((tt) => tt.id === wo.ticket_type_id),
            waitlist_order: wo,
          };
        })
      : wallet?.sell_waitlist_orders.map((wo) => {
          return {
            ...ticketTypes.find(
              (tt) =>
                tt.id === wo.display_ticket_type_id ||
                tt.id === wo.ticket_type_id
            ),
            waitlist_order: wo,
          };
        }) || [];

  const onLeaveWaitlistClick =
    (
      tt: KYDTicketType & {
        waitlist_order: KYDWaitlistBuyOrder | KYDWaitlistSellOrder;
      }
    ) =>
    (type: "buy" | "sell") => {
      dialog.open({
        title: "Confirm",
        text:
          type === "buy"
            ? `You will lose your spot in line to purchase tickets if they become available`
            : "You will lose your spot in line to sell tickets",
        actions: [
          {
            type: "cancel",
            text: "Cancel",
            props: {
              variant: "outline",
              borderRadius: "lg",
            },
          },
          {
            type: "confirm",
            text: type === "buy" ? "Leave waitlist" : "Leave waitlist",
            onClick: () => {
              onLeaveWaitlist(tt, tt.waitlist_order as KYDWaitlistBuyOrder);
              dialog.close();
            },
            props: {
              backgroundColor: "kydRed.200",
              color: "white",
              borderRadius: "lg",
              ml: 3,
              _hover: {
                backgroundColor: "gray.200",
              },
            },
          },
        ],
      });
    };

  const title = {
    buy: "YOU ARE ON THE LIST",
    sell: "YOU'VE LISTED YOUR TICKETS",
  };

  return (
    <VStack w="full">
      <WaitlistTopCard title={<Text>{title[type]}</Text>}>
        <Table my={4} w="full">
          <Thead>
            <Tr>
              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"left"}
              >
                Quantity
              </Th>
              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"left"}
              >
                Ticket type
              </Th>
              {type === "buy" ? (
                <Th
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"left"}
                >
                  Total Price
                </Th>
              ) : null}
              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"left"}
              >
                Status
              </Th>
              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"right"}
              >
                Joined on
              </Th>
              <Th
                textAlign={"right"}
                display={{ base: "none", md: "table-cell" }}
              ></Th>
            </Tr>
          </Thead>

          <Tbody>
            {waitlistTicketTypes.map((tt) => (
              <Tr key={tt.id}>
                <Td display={{ base: "none", md: "table-cell" }}>
                  {tt.waitlist_order.action === "SELL" ? (
                    <HStack>
                      <Text>{tt.waitlist_order.filled_quantity}</Text>
                      <Text>/</Text>
                      <Text>{tt.waitlist_order.ticket_ids?.length}</Text>
                    </HStack>
                  ) : (
                    <Text>{tt.waitlist_order.quantity}</Text>
                  )}
                </Td>
                <Td display={{ base: "none", md: "table-cell" }}>
                  <VStack
                    w="full"
                    justifyContent="flex-start"
                    alignItems={"flex-start"}
                  >
                    <Text>{tt.pool ? tt.pool.name : tt.name}</Text>
                  </VStack>
                </Td>
                {type === "buy" ? (
                  <Td display={{ base: "none", md: "table-cell" }}>
                    {tt?.waitlist_order.cart?.display_total}
                  </Td>
                ) : null}
                <Td display={{ base: "none", md: "table-cell" }}>
                  <Badge colorScheme={getBadgeColor(tt.waitlist_order)}>
                    {tt?.waitlist_order.status}
                  </Badge>
                </Td>
                <Td
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  {formatDate(
                    dayjs(tt?.waitlist_order.created_at).tz(timezone)
                  )}
                </Td>
                <Td
                  textAlign={"right"}
                  display={{ base: "none", md: "table-cell" }}
                >
                  <Button
                    isDisabled={loading.waitlist}
                    disabled={loading.waitlist}
                    isLoading={loading.waitlist}
                    backgroundColor="kydRed.200"
                    color="white"
                    borderRadius={"lg"}
                    onClick={() => {
                      onLeaveWaitlistClick(tt)(type);
                    }}
                  >
                    LEAVE WAITLIST
                  </Button>
                </Td>
                <Td display={{ base: "block", md: "none" }} px={0}>
                  <HStack spacing={4} w="full">
                    <VStack w="80%" alignItems={"flex-start"}>
                      <Text>
                        {tt.waitlist_order.quantity}
                        {tt.waitlist_order.ticket_ids?.length} x {tt.name}
                      </Text>
                      {tt.description ? <Text>{tt.description}</Text> : null}
                      {tt?.waitlist_order.cart ? (
                        <Text>{tt?.waitlist_order.cart?.display_total}</Text>
                      ) : null}
                      <Box mb={4}>
                        <Text>
                          {formatDate(dayjs(tt.waitlist_order.created_at))}
                        </Text>
                      </Box>
                      <Badge colorScheme={getBadgeColor(tt.waitlist_order)}>
                        {tt.waitlist_order.status}
                      </Badge>
                    </VStack>
                    <Button
                      w="30%"
                      isDisabled={loading.waitlist}
                      disabled={loading.waitlist}
                      isLoading={loading.waitlist}
                      backgroundColor="kydRed.200"
                      color="white"
                      borderRadius={"lg"}
                      onClick={() => {
                        onLeaveWaitlistClick(tt)(type);
                      }}
                    >
                      LEAVE
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <VStack w="full" my={4}>
          <Text>
            {type === "buy" ? (
              <>
                We will notify you via email if we are able to find tickets for
                you for the show.
                <InlineText fontWeight={"bold"} ml={1}>
                  This is not a ticket.
                </InlineText>
                <Text fontWeight={"bold"} mt={4}>
                  If we are able to find you tickets, your card will be charged
                  the amount above.
                </Text>
              </>
            ) : (
              <>
                We will notify you via email if we are able to find a buyer for
                your tickets. You are not guaranteed a refund, and you can
                unlist your tickets at anytime if change your mind.
              </>
            )}
          </Text>
        </VStack>
      </WaitlistTopCard>
    </VStack>
  );
};

export default WaitlistTicketTypes;
